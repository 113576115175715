import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        
        
        <div className="boper">
          <div className="wrapper">
            <header
              style={{
                height: "120px"
              }}
            >
              <div
                style={{
                  height: "67px"
                }}
              >
                <h1 className="logo">
                  <a href="/" />
                </h1>
                <nav>
                  <a href="/site/produkty/" className="link">
                    Produkty
                  </a>
                  <span>| </span>
                  <a href="/site/o-neutrogenie/kim-jestesmy/" className="link">
                    O Neutrogenie
                  </a>
                  <span>| </span>
                  <a href="/site/bad-piekna/" className="link">
                    Bądź piękna
                  </a>
                  <span>| </span>
                  <a href="/site/see-whats-possible/" className="current">
                    See What's Possible
                  </a>
                  <span>| </span>
                  <a href="/site/skora-z-niedoskonalosciami/" className="link">
                    Skóra z niedoskonałościami
                  </a>
                  <span>| </span>
                </nav>
              </div>
              <div
                style={{
                  "padding-left": "185px",
                  height: "53px"
                }}
              >
                <nav
                  className="second"
                  style={{
                    float: "none"
                  }}
                >
                  <a href="/site/hydroboost/" className="link">
                    Hydro Boost
                  </a>
                  <span>| </span>
                  <a href="/site/skin-detox/" className="link">
                    Skin Detox
                  </a>
                  <span>| </span>
                  <a href="/site/cellular-boost/" className="link">
                    Cellular Boost
                  </a>
                  <span />
                </nav>
              </div>
            </header>
            <div className="top">
              <img
                className="swphd"
                src="/site/assets/swp/fbcover-PL-960x374.png"
                alt
              />
            </div>
          </div>
          <section id="swp">
            <nav id="historia">
              <a className="current" href="/see-whats-possible/#historia">
                nasza historia
              </a>
              <a href="/see-whats-possible/#przemysl">
                zobacz, co jeszcze jest możliwe
              </a>
              <a href="/see-whats-possible/#produkty">nasze produkty</a>
            </nav>
            <section className="videos">
              <div>
                <article>
                  <h4>See What's Possible</h4>
                  <p>
                    <strong>Pasja, odwaga, ambicja,</strong>
                  </p>
                  <p>
                    <strong>wiara, że wszystko jest możliwe.</strong>
                  </p>
                  <p>
                    <strong>Zobacz, co czyni kobietę piękną!</strong>
                  </p>
                </article>
                <aside>
                  <a
                    className="vid"
                    href="/site/assets/swp/videos/Neutrogena-Beautiful-60s-v1.mp4"
                  >
                    <img
                      src="/site/assets/swp/covers/_resampled/croppedimage623372-vid1.jpg"
                      alt
                    />
                  </a>
                </aside>
              </div>
              <ul>
                <li>
                  <a
                    className="vid"
                    href="/site/assets/swp/videos/Neutrogena-TheySaid-pl-v4.mp4"
                  >
                    <img
                      src="/site/assets/swp/covers/_resampled/croppedimage378266-NEUTROGENA-SWP-kostka-376x265.jpg"
                      alt
                    />
                    <em>
                      NEUTROGENA® nieustannie tworzy, doskonali i odkrywa <br />{" "}
                      co jeszcze jest możliwe w pielęgnacji skóry.
                    </em>
                  </a>
                </li>
                <li>
                  <a
                    className="vid"
                    href="/site/assets/swp/videos/Neutrogena-Jennifer-Garner-v3-FB-60MB.mp4"
                  >
                    <img
                      src="/site/assets/swp/covers/_resampled/croppedimage378266-vid5.jpg"
                      alt
                    />
                    <em>
                      Poznaj Jennifer Garner – naszą ambasadorkę -<br />i
                      dowiedz się jakie cechy pozwoliły jej zrealizować
                      marzenia.
                    </em>
                  </a>
                </li>
              </ul>
              <a href="/see-whats-possible/#produkty" />
            </section>
            <nav id="przemysl">
              <a href="/see-whats-possible/#historia">nasza historia</a>
              <a className="current" href="/see-whats-possible/#przemysl">
                zobacz, co jeszcze jest możliwe
              </a>
              <a href="/see-whats-possible/#produkty">nasze produkty</a>
            </nav>
            <section className="rethink">
              <h4>Zobacz, co jeszcze jest możliwe</h4>
              <p>
                <strong>
                  NEUTROGENA<sup>®&nbsp;</sup>inspiruje i pomaga kobietom stawić
                  czoła każdemu wyzwaniu.
                </strong>
              </p>
              <p>
                <strong>
                  Gdy tworzymy produkty nie ma dla nas rzeczy niemożliwych.
                </strong>
              </p>
              <p>
                <strong>Dla Ciebie też!</strong>
              </p>
              <ul>
                <li>
                  <img
                    src="/site/assets/swp/packshots/_resampled/paddedimage282282-NEUTROGENA-Odzywczy-krem-do-stop-800x800.jpg"
                    alt
                  />
                  <a
                    target="_blank"
                    href="https://www.facebook.com/NeutrogenaPolska"
                  />
                </li>
                <li>
                  <iframe src="https://www.youtube-nocookie.com/embed/GRAyYovyvsY?playlist=GRAyYovyvsY&rel=0&controls=0&showinfo=0&autohide=1&autoplay=1&loop=1&iv_load_policy=3&modestbranding=1&theme=light" />
                </li>
                <li>
                  <img
                    src="/site/assets/swp/packshots/_resampled/paddedimage282282-NEUTROGENA-Visibly-Clear-Pink-Grapefruit-Facial-Wash-ver2-800x723.jpg"
                    alt
                  />
                  <a
                    target="_blank"
                    href="https://www.facebook.com/NeutrogenaPolska"
                  />
                </li>
                <li>
                  <iframe src="https://www.youtube-nocookie.com/embed/VZ9WZtob_-k?playlist=VZ9WZtob_-k&rel=0&controls=0&showinfo=0&autohide=1&autoplay=1&loop=1&iv_load_policy=3&modestbranding=1&theme=light" />
                </li>
                <li>
                  <img
                    src="/site/assets/swp/packshots/_resampled/paddedimage282282-NEUTROGENA-Visibly-Renew-emulsja-do-ciala-800x800-bez-subbanera.jpg"
                    alt
                  />
                  <a
                    target="_blank"
                    href="https://www.facebook.com/NeutrogenaPolska"
                  />
                </li>
                <li>
                  <iframe src="https://www.youtube-nocookie.com/embed/ZJ0LwnND9es?playlist=ZJ0LwnND9es&rel=0&controls=0&showinfo=0&autohide=1&autoplay=1&loop=1&iv_load_policy=3&modestbranding=1&theme=light" />
                </li>
                <li>
                  <img
                    src="/site/assets/swp/packshots/_resampled/paddedimage282282-NEUTROGENA-Formua-Norweska-Krem-do-rak-800x800-bez-subbanera2.jpg"
                    alt
                  />
                  <a
                    target="_blank"
                    href="https://www.facebook.com/NeutrogenaPolska"
                  />
                </li>
                <li>
                  <iframe src="https://www.youtube-nocookie.com/embed/xK3l2M8YMXY?playlist=xK3l2M8YMXY&rel=0&controls=0&showinfo=0&autohide=1&autoplay=1&loop=1&iv_load_policy=3&modestbranding=1&theme=light" />
                </li>
              </ul>
            </section>
            <nav id="produkty">
              <a href="/see-whats-possible/#historia">nasza historia</a>
              <a href="/see-whats-possible/#przemysl">
                zobacz, co jeszcze jest możliwe
              </a>
              <a className="current" href="/see-whats-possible/#produkty">
                nasze produkty
              </a>
            </nav>
            <section className="products">
              <h4>
                Poznaj produkty zainspirowane
                <br />
                potrzebami współczesnych kobiet.
              </h4>
              <ul className="prod">
                <li>
                  <aside>
                    <img
                      src="/site/assets/swp/products/NEUTROGENA-Formua-Norweska-Krem-do-rak-50-x2.jpg"
                      alt
                    />
                  </aside>
                  <article>
                    <h5>
                      NEUTROGENA<sup>®</sup> Formuła Norweska Krem do rąk
                      bezzapachowy
                    </h5>
                    <p>
                      Krem do rąk, którego już jedna kropla nawilża dłonie przez
                      całą dobę!
                    </p>
                    <a
                      className="ytvid"
                      href="https://www.youtube.com/embed/BZK4c6hyN7g?autoplay=1"
                    >
                      <img
                        src="https://img.youtube.com/vi/BZK4c6hyN7g/hqdefault.jpg"
                        alt
                      />
                    </a>
                    <a className="btn" href="/product/20/">
                      Więcej
                    </a>
                  </article>
                </li>
                <li>
                  <article>
                    <h5>
                      NEUTROGENA<sup>®</sup> Formuła Norweska Visibly Renew
                      Elasticity Boost{" "}
                    </h5>
                    <p>
                      Emulsja do ciała, która poprawia elastyczność skóry już w
                      10 dni.
                    </p>
                    <a
                      className="ytvid"
                      href="https://www.youtube.com/embed/l-qo85zG0u4?autoplay=1"
                    >
                      <img
                        src="https://img.youtube.com/vi/l-qo85zG0u4/hqdefault.jpg"
                        alt
                      />
                    </a>
                    <a className="btn" href="/product/43/">
                      Więcej
                    </a>
                  </article>
                  <aside>
                    <img
                      src="/site/assets/swp/products/visibly-renew-emulsja-do-ciala-3574661199283-PL-223x455.jpg"
                      alt
                    />
                  </aside>
                </li>
                <li>
                  <aside>
                    <img
                      src="/site/assets/swp/products/3-VisiblyClear-PG-Facial-Wash-HIGH-RES-195x455.jpg"
                      alt
                    />
                  </aside>
                  <article>
                    <h5>
                      NEUTROGENA<sup>®</sup> Visibly Clear<sup>®</sup> Pink
                      Grapefruit <br /> Facial Wash
                    </h5>
                    <p>
                      Głęboko oczyszczający żel do mycia twarzy, który odświeża
                      i pięknie pachnie.
                    </p>
                    <a
                      className="ytvid"
                      href="https://www.youtube.com/embed/TxF0jtpoxQg?autoplay=1"
                    >
                      <img
                        src="https://img.youtube.com/vi/TxF0jtpoxQg/hqdefault.jpg"
                        alt
                      />
                    </a>
                    <a className="btn" href="/product/47/">
                      Więcej
                    </a>
                  </article>
                </li>
              </ul>
            </section>
          </section>
          <div className="bluebar">
            <div className="bar">
              <div className="wrapper">
                <a href="/site/o-neutrogenie/kim-jestesmy/">
                  neutrogena<sup>®</sup> obecnie
                </a>
                <span>|</span>
                <a href="/site/o-neutrogenie/formula-norweska/">
                  czym jest formuła norweska?
                </a>
                <span>|</span>
                <a href="/site/o-neutrogenie/historia/">
                  poznaj naszą historię
                </a>
                <span>|</span>
                <a href="/site/produkty/">zobacz produkty neutrogena</a>
              </div>
            </div>
          </div>
          <footer>
            <div className="wrapper">
              <nav>
                <a href="/site/" className="logo2" />
                <a href="/site/nota-prawna/">Nota Prawna</a>
                <a href="/site/polityka-prywatnosci/">Polityka Prywatności</a>
                <a href="/site/cookies/">Polityka cookies</a>
                 <a href="/site/kontakt/">Zachęcamy do kontaktu</a>                   
                           <span>© JNTL Consumer Health (Poland) sp. z o.o. 2023 </span>
            </nav><a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
              <p>
                Strona zarządzana jest przez JNTL Consumer Health (Poland) sp. z o.o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w
                Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy
                Krajowego Rejestru Sądowego, pod numerem KRS 00000322278, NIP
                113-00-20-467, wysokość kapitału zakładowego: 39.751.500 PLN.
                JNTL Consumer Health (Poland) sp. z o.o. ponosi wyłączną
                odpowiedzialność za treści zawarte na niniejszej stronie.
                <br /> Strona jest przeznaczona dla użytkowników z Polski.
                Ostatnia aktualizacja: 01/02/2023
                <br />{" "}
              </p>{" "}
              <p>
                <span>
                  Strona została zoptymalizowana pod kątem przeglądarek:
                  Internet Explorer 7 i wyższych, Mozilla Firefox, Google
                  Chrome, Opera, Safari
                </span>{" "}
                <span className="right">
                  Zalecana rozdzielczość: 1024 x 768 pikseli
                </span>{" "}
              </p>
            </div>
          </footer>
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html:
                "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-N7SHM8');"
            }}
          />
          <script type="text/plain" class="optanon-category-4" 
            dangerouslySetInnerHTML={{
              __html:
                "\n!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?\nn.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;\nn.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;\nt.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,\ndocument,'script','https://connect.facebook.net/en_US/fbevents.js');\nfbq('init', '227575234312850'); // Insert your pixel ID here.\nfbq('track', 'PageView');\n"
            }}
          />
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">'
            }}
          />
        </div>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "    \t\n$(function() {\n$('#swp nav a,#swp .videos > a').click(function() {\n$('html,body').animate({\nscrollTop: $($(this).attr('href')).offset().top+'px'\n},1000);\nreturn false;\n}).each(function() {\nvar h = $(this).attr('href');\n$(this).attr('href',h.substring(h.indexOf('#')));\n});\n$('#swp a.vid').click(function() {\n$('#swp .videos').append('<figure><div><video controls preload=\"auto\"><source type=\"video/mp4\" src=\"'+$(this).attr('href')+'\" /></div><dfn></dfn></figure>');\n$('#swp .videos figure video').each(function() {\nthis.load();\n$(this).on('canplaythrough',function() {\nthis.play();\n});\n});\n$('#swp .videos figure dfn').click(function() {\n$('#swp .videos figure video').each(function() {\nthis.pause();\n$(this).remove();\t\t\t\t\t\t\t\n}); \n$('#swp .videos figure').empty().remove();\n});\nreturn false;\n});\n$('#swp a.ytvid').click(function() {\n$('body').append('<section class=\"yt\"><div><iframe src=\"'+$(this).attr('href')+'\"></iframe></div><dfn></dfn></section>');\n$('body > section.yt dfn').click(function() {\n$('body > section.yt').remove();\nreturn false;\n});\nreturn false;\n});\n});\n"
          }}
        />
        <script src="/site/themes/simple/js/slides.min.jquery.js" />
        <script src="/site/themes/simple/js/slide.js" />
      </div>
    );
  }
}

export default Page;
